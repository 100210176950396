import type { LinkProps } from '@remix-run/react'
import { ROUTES } from '~/constants/routes'
import { useLocale } from '~/hooks/useLocale'
import type { Locale } from '~/i18n/config'
import {
  matchIsLinkToStartWithLocalePrefix,
  setLocalePrefixToLinkTo
} from '~/utils/i18n-link'

type LinkTo = LinkProps['to']

const ROUTES_VALUES = Object.values(ROUTES)

function makeToLocalePrefixed(to: LinkTo, locale: Locale): LinkTo {
  return matchIsLinkToStartWithLocalePrefix(to, locale)
    ? to
    : setLocalePrefixToLinkTo(to, locale)
}

export function matchIsKnownRoute(to: LinkTo) {
  const pathname = typeof to === 'object' ? to.pathname! : to
  const isHomePage = pathname === '/'

  return (
    isHomePage ||
    ROUTES_VALUES.some((route) => {
      return pathname.startsWith(route)
    })
  )
}

export function useLinkPrefixedWithLocale(to: LinkTo) {
  const locale = useLocale()

  if (matchIsKnownRoute(to)) {
    return makeToLocalePrefixed(to, locale)
  }

  return to
}
